import React, { ReactElement, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'

import { Button } from 'components'

import styles from 'styles/Home.module.scss'

interface Props {
  contents: string[]
}

function HomeBanner(props: Props): ReactElement {
  const { contents = [] } = props
  const [current] = useState(0)

  return (
    <div className={styles.bannerWrapper}>
      <Image
        src="/images/banner-md.png"
        alt="banner"
        objectFit="cover"
        objectPosition="center"
        layout="fill"
        className={styles.bannerBackground}
      />
      <div className={styles.bannerContainer}>
        <h1 className={styles.bannerTitle}>Lambskin Baby Shoes</h1>
        <p className={styles.bannerContent}>{contents[current]}</p>
        <Link href="/gallery" passHref>
          <Button className={styles.bannerDetailBtn}>ดูรายละเอียด</Button>
        </Link>
      </div>
    </div>
  )
}

export default HomeBanner
