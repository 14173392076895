import React, { ReactElement } from 'react'
import Image from 'next/image'

import styles from 'styles/Home.module.scss'

type Service = {
  image: string
  desc: string
}

interface Props {
  services: Service[]
}

function HomeServices(props: Props): ReactElement {
  const { services = [] } = props

  return (
    <div className={styles.serviceWrapper}>
      <ul className={styles.serviceList}>
        {services.map((service) => {
          return (
            <li key={service.desc} className={styles.serviceItem}>
              <div className={styles.serviceImage}>
                <Image src={service.image} layout="fill" alt="service photo" />
              </div>

              <label
                htmlFor="service description"
                className={styles.serviceDesc}
              >
                {service.desc}
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default HomeServices
