import React, { ReactElement } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

import styles from 'styles/Home.module.scss'

interface Props {
  links: string[]
}

const MAX_IMAGE = 12

function HomeGallery(props: Props): ReactElement {
  const { links = [] } = props

  const mergeLinks =
    links.length === 0
      ? Array(MAX_IMAGE).fill(0)
      : links.length !== MAX_IMAGE
      ? Array(MAX_IMAGE).fill(0)
      : links

  return (
    <div className={styles.galleryWrapper}>
      <div className={styles.galleryContainer}>
        <h1 className={styles.galleryTitle}>Gallery</h1>
      </div>
      <ul className={styles.galleryList}>
        {mergeLinks.map((link, index) => {
          return (
            <li
              key={index}
              className={clsx([
                styles.galleryItem,
                styles[`galleryItem${index + 1}`],
              ])}
            >
              {link ? (
                <Image
                  alt="gallery image"
                  src={link}
                  layout="fill"
                  blurDataURL={link}
                />
              ) : (
                <div className={styles.galleryItemEmpty}></div>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default HomeGallery
