import React, { ReactElement } from 'react'
import Image from 'next/image'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Keyboard, Navigation, Autoplay } from 'swiper'
import IconCheck from '../../public/svg/ic-check.svg'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import 'swiper/css'
SwiperCore.use([Keyboard, Navigation, Autoplay])

import styles from 'styles/Home.module.scss'

type Customer = {
  image: string
  review: string
}

interface Props {
  customers: Customer[]
}

function HomeCustomerReview(props: Props): ReactElement {
  const { customers = [] } = props

  const contentList = [
    {
      text: 'ผลิตจากหนังแกะ ผิวสัมผัสจึงนุ่มและยืดหยุ่นสูง ทำให้ใส่สบายไม่ระคายเคืองต่อผิวลูกน้อย',
    },
    { text: 'รองเท้ามีน้ำหนักเบา ทำให้เคลื่อนไหวได้คล่องตัว' },
    { text: 'รองเท้ามีน้ำหนักเบา ทำให้เคลื่อนไหวได้คล่องตัว' },
    {
      text: 'อายุการใช้งานนานมาก ไม่แตกไม่ร่อนเหมือนรอง เท้าหนังเทียม สามารถเก็บเป็นความทรงจำ “รองเท้าคู่แรก” ให้ลูกน้อยดูตอนโต',
    },
    { text: 'ดีไซน์ดูดี ไม่เหมือนใคร สามารถปักชื่อได้' },
    {
      text: 'ตัดเย็บปราณีต ด้วยช่างหนังแท้มืออาชีพ มากประสบการณ์ แฮนด์เมคทุกคู่',
    },
  ]

  return (
    <div className={styles.customerWrapper}>
      <Image
        src="/images/customer-review-bg.png"
        alt="customer review image"
        layout="fill"
      />
      <Image
        src="/images/customer-review-bg-mask.png"
        alt="customer review image mask"
        className={styles.customerBgMask}
        layout="fill"
      />
      <div className={styles.customerColumn}>
        <div className={styles.customerContainer}>
          <h1 className={styles.customerTitle}>Customer Review</h1>
          <h2 className={styles.customerSubTitle}>
            คุณสมบัติรองเท้า Baby Step ที่ลูกค้า ใช้แล้วต้อง บอกต่อ
          </h2>
          <div className={styles.customerContent}>
            <ul className={styles.customerList}>
              {contentList.map((content, index) => {
                return (
                  <li key={index} className={styles.customerItem}>
                    <IconCheck />
                    <span>{content.text}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <div className={styles.customerSection}>
          <Swiper
            slidesPerView={1}
            initialSlide={1}
            spaceBetween={30}
            keyboard={{
              enabled: true,
            }}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            loop={true}
            navigation={true}
            className={styles.customerSlide}
          >
            {customers.map((customer, index) => {
              return (
                <SwiperSlide key={index}>
                  <Image
                    src={customer.image}
                    alt="customer review image"
                    layout="fill"
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default HomeCustomerReview
