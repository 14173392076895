import React, { ReactElement } from 'react'
import { noop } from 'lodash'
import { useForm } from 'react-hook-form'

import { Button, Input } from 'components'
import styles from 'styles/Footer.module.scss'

type RegistrationFormProp = {
  username: string
  password: string
}
interface RegistrationProps {
  onSubmit: (values: RegistrationFormProp) => void
}

function HomeRegistration(props: RegistrationProps): ReactElement {
  const { register, handleSubmit } = useForm()
  const { onSubmit = noop } = props

  return (
    <div className={styles.registration}>
      <h1 className={styles.registrationTitle}>เข้าสู่ระบบ</h1>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.registrationForm}
      >
        <div>
          <Input
            type="email"
            {...register('email', { required: true })}
            placeholder="อีเมล"
            fullWidth
          />
        </div>
        <div>
          <Input
            type="password"
            {...register('password', { required: true })}
            placeholder="รหัสผ่าน"
            fullWidth
          />
        </div>
        <div className={styles.registrationSubmit}>
          <Button
            type="submit"
            variants="secondary"
            className={styles.registrationBtn}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default HomeRegistration
