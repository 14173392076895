import React, { ReactElement } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

import IconFlower from '../../public/svg/ic-flower.svg'
import styles from 'styles/Home.module.scss'

interface Props {
  message: string
}

function HomeQuotes(props: Props): ReactElement {
  const { message = [] } = props

  return (
    <div className={styles.quotesWrapper}>
      <div className={clsx(['container', styles.quotesSection])}>
        <span className={styles.quotesImage}>
          <Image
            alt="quotation"
            src="/images/quotation.png"
            height={100}
            width={138}
            layout="fixed"
          />
        </span>
        <p className={styles.quotesMessage}>{message}</p>
        <p className={styles.quotesCopyright}>
          <span className={styles.quotesOpen}>
            <IconFlower />
          </span>
          Baby Step
          <span className={styles.quotesClose}>
            <IconFlower />
          </span>
        </p>
      </div>
    </div>
  )
}

export default HomeQuotes
