import type { NextPage } from 'next'
import Head from 'next/head'

import { LandingLayout } from 'layouts'
import {
  HomeBanner,
  HomeServices,
  HomeQuotes,
  HomeGallery,
  HomePromotion,
  HomeCustomerReview,
} from 'features/Home'

const Home: NextPage = () => {
  const contents = [
    'Baby Step รองเท้าหนังแกะแท้คุณภาพ สูง นุ่ม ไม่ระคาย เคืองเท้าที่สำคัญเราใส่ ใจรายละเอียดทุกคู่ Hand Made ทุกคู่ ตัดเย็บอย่างปราณีตด้วยช่างหนังแท้ยอดฝีมือ และมากด้วยประสบการณ์ เราใส่ ใจก้าวแรกของลูกน้อยเพราะเป็นก้าวที่ สำคัญที่สุด',
  ]

  const services = [
    {
      image: '/images/card-birthday.png',
      desc: 'บริการเขียนการ์ด',
    },
    {
      image: '/images/card-gift.png',
      desc: 'บริการกล่องของขวัญ',
    },
    {
      image: '/images/card-picture.png',
      desc: 'ปริ้นท์ภาพสุดประทับใจ',
    },
    {
      image: '/images/card-express-delivery.png',
      desc: 'บริการส่งด่วนในกทม.',
    },
  ]

  const promotions = [
    {
      title: 'Best Seller',
      subTitle: 'Baby Step รุ่น Signature 3 in 1',
      image: '/images/promotion-best-seller.jpg',
      link: '',
    },
    {
      title: 'DUO mom&me',
      subTitle: 'รุ่น Bovvy รองเท้าคู่แม่ลูก',
      image: '/images/promotion-duo.jpg',
      link: '',
    },
    {
      title: 'New Arrival',
      subTitle: 'รุ่น Boothy เท่ อย่างมีสไตล์',
      image: '/images/promotion-new-arrival.jpg',
      link: '',
    },
    {
      title: 'Special Offer',
      subTitle: 'Box Set ราคาพิเศษ',
      image: '/images/promotion-special-offer.jpg',
      link: '',
    },
    {
      title: 'Cute Baby Stuff',
      subTitle: 'ของใช้เด็ก must have items !!',
      image: '/images/promotion-cute-baby-stuff.jpg',
      link: '',
    },
    {
      title: 'Babywear',
      subTitle: 'เสื้อผ้าเด็ก Baby Fashionista',
      image: '/images/promotion-babywear.jpg',
      link: '',
    },
  ]

  const customers = [
    { image: '/images/customer-reveiw-1.jpg', review: '' },
    { image: '/images/customer-reveiw-2.jpg', review: '' },
    { image: '/images/customer-reveiw-3.jpg', review: '' },
    { image: '/images/customer-reveiw-4.jpg', review: '' },
    { image: '/images/customer-reveiw-5.jpg', review: '' },
    { image: '/images/customer-reveiw-6.jpg', review: '' },
    { image: '/images/customer-reveiw-7.jpg', review: '' },
    { image: '/images/customer-reveiw-8.jpg', review: '' },
    { image: '/images/customer-reveiw-9.jpg', review: '' },
    { image: '/images/customer-reveiw-10.jpg', review: '' },
  ]

  const links = [
    '/images/customer-showcases-1.jpg',
    '/images/customer-showcases-2.jpg',
    '/images/customer-showcases-3.jpg',
    '/images/customer-showcases-4.jpg',
    '/images/customer-showcases-5.jpg',
    '/images/customer-showcases-6.jpg',
    '/images/customer-showcases-7.jpg',
    '/images/customer-showcases-8.jpg',
    '/images/customer-showcases-9.jpg',
    '/images/customer-showcases-10.jpg',
    '/images/customer-showcases-11.jpg',
    '/images/customer-showcases-12.jpg',
  ]

  return (
    <>
      <Head>
        <title>Babystep</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <LandingLayout>
        <HomeBanner contents={contents} />
        <HomeServices services={services} />
        <HomeQuotes message="because the first step is very important" />
        <HomePromotion promotions={promotions} />
        <HomeCustomerReview customers={customers} />
        <HomeGallery links={links} />
      </LandingLayout>
    </>
  )
}

export default Home
