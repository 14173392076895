import React, { ReactElement } from 'react'
import Image from 'next/image'

import styles from 'styles/Home.module.scss'

type Promotion = {
  title: string
  subTitle: string
  link: string
  image: string
}

interface Props {
  promotions: Promotion[]
}

function HomePromotion(props: Props): ReactElement {
  const { promotions = [] } = props

  return (
    <div className={styles.promotionWrapper}>
      <div className={styles.promotionBg}>
        <span className={styles.promotionBg1}>
          <Image
            src="/images/promotion-bg-shoes.png"
            alt="background shoes"
            layout="fixed"
            width={227}
            height={310}
          />
        </span>
        <span className={styles.promotionBg2}>
          <Image
            src="/images/promotion-bg-point-blue.png"
            alt="background shoes"
            layout="fixed"
            width={473}
            height={214}
          />
        </span>
        <span className={styles.promotionBg3}>
          <Image
            src="/images/promotion-bg-point-pink.png"
            alt="background shoes"
            layout="fixed"
            width={473}
            height={214}
          />
        </span>
        <span className={styles.promotionBg4}>
          <Image
            src="/images/promotion-bg-shoes-green.png"
            alt="background shoes green"
            layout="fixed"
            height={219}
            width={244}
          />
        </span>
      </div>
      <ul className={styles.promotionList}>
        {promotions.map((promotion) => {
          return (
            <li key={promotion.title} className={styles.promotionItem}>
              <div className={styles.promotionImageWrapper}>
                <Image
                  src={promotion.image}
                  alt={promotion.title}
                  layout="fill"
                  objectFit="cover"
                />
              </div>

              <div className={styles.promotionTopic}>
                <h3 className={styles.promotionTitle}>{promotion.title}</h3>
                <h4 className={styles.promotionSubTitle}>
                  {promotion.subTitle}
                </h4>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default HomePromotion
